<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 3V21H22V3H2ZM10 17H5V15H10V17ZM10 13H5V11H10V13ZM10 
      9H5V7H10V9ZM14.82 15L12 12.16L13.41 10.75L14.82 12.17L17.99 9L19.41 10.42L14.82 15Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcAudit',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
