var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page',{staticClass:"main-container base-scrollbar"},[_c('page-section',{attrs:{"full-width":"","bottom-border":""}},[_c('div',{staticClass:"flex flex-row justify-between",class:{ 'mx-20': _vm.isExternal }},[_c('page-title',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ic-audit')]},proxy:true}])},[_vm._v(" Audit ")]),_c('div',{staticClass:"flex flex-row space-x-4 items-center"},[_c('merge-button-round',{attrs:{"button-type":"brand-purple","disabled":_vm.isLoading || _vm.hasPendingAudit || !!_vm.auditsError},on:{"click":_vm.generateNewAudit}},[_c('p',[_vm._v("Run Audit")])])],1)],1)]),(_vm.hasPendingAudit && !_vm.latestCompletedAudit && !_vm.auditsError)?_c('base-alert',{staticClass:"max-w-5xl mx-auto my-6",attrs:{"type":"information"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Audit running...")]},proxy:true},{key:"content",fn:function(){return [_vm._v(" You have an audit generating in the background, check back later to see the result!")]},proxy:true}],null,false,1043469719)}):_vm._e(),(_vm.isLoading || (_vm.hasPendingAudit && !_vm.latestCompletedAudit && !_vm.auditsError))?_c('AuditLoader',{class:{ 'mx-28': _vm.isExternal }}):_vm._e(),(!_vm.isLoading && _vm.latestCompletedAudit && !_vm.auditsError)?_c('div',{class:{ 'mx-28': _vm.isExternal }},[_c('div',{staticClass:"py-4 px-8 flex flex-col md:flex-row gap-4 w-full items-center justify-between border-b border-neutral-50",class:{
        'bg-neutral-0': !_vm.isExternal,
        'bg-white': _vm.isExternal,
      }},[_c('p',{staticClass:"h4"},[_vm._v(_vm._s(_vm.auditHeader))]),_c('p',{staticClass:"p3 text-bb-neutral-gray"},[_vm._v(" Last audit run: "+_vm._s(_vm.daysSinceLastCreatedAudit)+" - Compared to: "+_vm._s(_vm.lastCompletedAuditDate)+" ")])]),_c('div',{staticClass:"px-3 bg-white"},[_c('AuditSummary',{attrs:{"summary":_vm.auditSummary}})],1),_c('div',{staticClass:"sticky top-0 bg-white z-10 flex-row w-full justify-evenly"},[_c('div',{staticClass:"pt-4 mx-4 flex"},[_c('div',{staticClass:"w-1/2 text-center flex flex-row justify-center gap-2 pb-3",class:{
            'border-b-2 border-bb-brand-purple text-bb-brand-purple': _vm.selectedView === 'issues',
            'border-b border-neutral-50 text-bb-neutral-gray hover:border-bb-brand-purple hover:text-bb-brand-purple cursor-pointer':
              _vm.selectedView !== 'issues',
          },on:{"click":function($event){_vm.selectedView = 'issues'}}},[_c('ic-alert-circle'),_c('p',{staticClass:"h5"},[_vm._v("Issues")])],1),_c('div',{staticClass:"px-2 py-1"},[_c('div',{staticClass:"w-px h-full bg-neutral-50"})]),_c('div',{staticClass:"w-1/2 text-center flex flex-row justify-center gap-2 pb-3",class:{
            'border-b-2 border-bb-brand-purple text-bb-brand-purple': _vm.selectedView === 'categories',
            'border-b border-neutral-50 text-bb-neutral-gray hover:border-bb-brand-purple hover:text-bb-brand-purple cursor-pointer':
              _vm.selectedView !== 'categories',
          },on:{"click":function($event){_vm.selectedView = 'categories'}}},[_c('ic-alert-circle'),_c('p',{staticClass:"h5 font-medium"},[_vm._v("Categories")])],1)])]),_c('page-section',{attrs:{"full-width":""}},[(_vm.selectedView === 'issues')?_c('div',[_c('AuditCheckList',{attrs:{"checks":_vm.auditDetails.checks},on:{"openModal":_vm.selectSubcheck}})],1):_vm._e(),(_vm.selectedView === 'categories')?_c('div',{staticClass:"h-1/2"},[_c('UnderConstruction')],1):_vm._e()])],1):_vm._e(),(_vm.showModal === true)?_c('bb-base-modal',{attrs:{"width":_vm.modalConfig.width},on:{"close":_vm.closeModal},scopedSlots:_vm._u([{key:"container",fn:function(){return [_c('AuditReportModal',{attrs:{"check-id":_vm.selectedSubcheck,"audit-id":_vm.latestAudit.id,"site-id":_vm.selectedSite.value},on:{"close":_vm.closeModal}})]},proxy:true}],null,false,2104441682)}):_vm._e(),(_vm.auditsError && !_vm.isLoading)?_c('div',[_c('div',{staticClass:"flex flex-col items-center text-center mt-20"},[_c('img',{staticClass:"mb-6",attrs:{"src":"/content/images/brightbid/bidbot-broken.svg"}}),_c('p',{staticClass:"h3"},[_vm._v("Audit failed...")]),_c('p',[_vm._v("Please try to refresh the page and start a new Audit.")])])]):_vm._e(),(!_vm.isLoading && !_vm.audits && !_vm.auditsError)?_c('div',{staticClass:"flex flex-col justify-center mt-32"},[_c('div',{staticClass:"flex flex-row w-full justify-between items-center max-w-215 mx-auto"},[_c('div',{staticClass:"w-full"},[_c('img',{attrs:{"src":"/content/images/brightbid/audit-artwork.svg"}})]),_c('div',{staticClass:"flex flex-col my-16"},[_c('p',{staticClass:"h3 mb-4"},[_vm._v("Welcome to your Audits")]),_c('p',[_vm._v(" Start your first audit to receive an account score, detailed insights and actionable recommendations to enhance your performance! ")]),_c('merge-button-round',{staticClass:"mr-auto mt-6",attrs:{"button-type":"primary"},on:{"click":_vm.generateNewAudit}},[_c('p',[_vm._v("Run Audit")])])],1)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }