<template>
  <div class="p-4 flex flex-col gap-10">
    <bb-base-card
      :checkbox="false"
      hover-disabled
      v-for="(checks, severity) in checksBySeverity"
      :key="severity"
    >
      <template #first>
        <div class="flex flex-row items-center gap-2">
          <component
            :is="severityContent[severity].icon"
            class="p-1 rounded-full"
            :class="severityContent[severity].headerClass"
          />
          <p class="h4">
            <span>{{ checks.length }}</span> {{ severityContent[severity].title }}
          </p>
        </div>
      </template>
      <template #expanded-content>
        <div class="flex flex-col rounded-lg bg-neutral-0">
          <div
            class="w-full py-7 flex flex-row justify-between items-center px-10 border-b border-neutral-100"
            v-for="check in checks"
            :key="check.id"
          >
            <div class="flex flex-row gap-2 items-center">
              <component
                :is="severityContent[check.severity].icon"
                class="p-1 rounded-full"
                :class="severityContent[check.severity].iconClass"
              />
              <p>{{ check.title }}</p>
            </div>
            <div class="grid grid-cols-3 gap-2 items-center">
              <p
                class="h5 font-bold px-2 rounded-full ml-auto text-right mr-4"
                :class="severityContent[check.severity].headerClass"
              >
                {{ check.issues_count }}
              </p>
              <pill :color="'gray'">
                <template #text>
                  <p class="text-bb-neutral-gray">{{ check.category }}</p>
                </template>
              </pill>
              <merge-button-round
                button-type="tertiary"
                @click="openModal(check.id)"
              >
                <p>See more</p>
              </merge-button-round>
            </div>
          </div>
        </div>
      </template>
    </bb-base-card>
  </div>
</template>

<script>
import IcAlertCircle from 'vue-material-design-icons/AlertCircleOutline'
import IcAlertTriangle from 'vue-material-design-icons/AlertOutline'
import Pill from '@/components/input/Pill.vue'
import IcLightbulbOutline from 'vue-material-design-icons/LightbulbOutline'
import IcCheckCircleOutline from 'vue-material-design-icons/CheckCircleOutline'
import bbBaseCard from '@/components/card/bbBaseCard.vue'

export default {
  name: 'AuditCheckList',
  components: {
    IcAlertCircle,
    IcAlertTriangle,
    Pill,
    IcLightbulbOutline,
    IcCheckCircleOutline,
    bbBaseCard,
  },
  props: {
    checks: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    checksBySeverity() {
      return this.checks.reduce((acc, check) => {
        if (!acc[check.severity]) {
          acc[check.severity] = []
        }
        acc[check.severity].push(check)
        return acc
      }, {})
    },
    severityContent() {
      return {
        urgent: {
          icon: 'ic-alert-triangle',
          headerClass: 'text-bb-error bg-bb-red-50',
          iconClass: 'text-bb-error',
          backgroundClass: 'bg-bb-red-50',
          title: 'Urgent Issues',
        },
        warning: {
          icon: 'ic-alert-circle',
          headerClass: 'text-bb-warning bg-bb-yellow-50',
          iconClass: 'text-bb-warning',
          backgroundClass: 'bg-bb-yellow-50',
          title: 'Warnings',
        },
        optimization: {
          icon: 'ic-lightbulb-outline',
          headerClass: 'text-bb-brand-purple bg-bb-secondary-purple',
          iconClass: 'text-bb-brand-purple',
          backgroundClass: 'bg-bb-secondary-purple',
          title: 'Optimizations',
        },
        passed: {
          icon: 'ic-check-circle-outline',
          headerClass: 'text-success bg-bb-success-bg',
          iconClass: 'text-success',
          backgroundClass: 'bg-bb-success-bg',
          title: 'Passed checks',
        },
      }
    },
  },
  methods: {
    openModal(checkId) {
      this.$emit('openModal', checkId)
    },
  },
}
</script>
