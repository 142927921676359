<template>
  <div class="flex items-center gap-2">
    <div
      id="progress-container"
      :class="containerWidth"
      :style="{ height: containerHeight }"
    >
      <div
        id="progress-bar"
        :style="progressBarStyle"
      ></div>
      <div
        id="progress-mark"
        :style="progressMarkStyle"
      ></div>
    </div>
    <div
      v-if="showPercentLabel"
      class="pNum mt-0.5"
      :style="{ color: progressBarColor }"
    >
      {{ displayValue }}%
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    value: {
      type: Number,
      required: false,
      default: 0, // Default value if not provided
    },
    containerWidth: {
      type: String,
      required: false,
      default: 'w-20',
    },
    containerHeight: {
      type: String,
      required: false,
      default: '13px', // Default height
    },
    showPercentLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    progressMark: {
      type: Number,
      required: false,
      default: 75,
    },
    customRange: {
      type: Object,
      default: () => {
        return {
          breakpoints: [75, 99],
          colors: ['linear-gradient(93deg, #4FD99F 0%, #57FFC3 100%)', '#FFA800', '#F23F44'],
        }
      },
    },
    useSolidColor: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    displayValue() {
      return Math.round(this.value ?? 0) // Null handling for display
    },
    progressBarWidth() {
      return ((this.value ?? 0) * (this.progressMark ?? 100)) / 100 // Null handling for progress bar width
    },
    progressBarColor() {
      if (this.useSolidColor) {
        return '#5698ff' // Solid color option
      }

      const colorIdx = this.customRange.breakpoints.findIndex(item => {
        return this.value < item
      })

      if (colorIdx === -1) {
        return this.customRange.colors[this.customRange.colors.length - 1]
      }

      return this.customRange.colors[colorIdx]
    },
    progressBarStyle() {
      return {
        width: this.progressBarWidth + '%',
        background: this.progressBarColor,
        height: '100%',
        borderTopLeftRadius: '6px',
        borderBottomLeftRadius: '6px',
      }
    },
    progressMarkStyle() {
      return {
        backgroundColor: this.progressBarColor === '#F23F44' ? '#A50505' : '#6d7883', // Changes color to #A50505 if bar is red
        left: this.progressMark + '%',
      }
    },
  },
}
</script>

<style>
#progress-container {
  background-color: #f0f1f3;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}

#progress-bar {
  transition: width 0.3s ease;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

#progress-mark {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
}
</style>
