<template>
  <div
    key="subCheck"
    class="max-h-screen overflow-y-auto mx-auto"
  >
    <div class="flex flex-row justify-items-start pt-7 mx-10">
      <pill :color="'lightGray'">
        <template #text>
          <p class="capitalize">{{ subCheck.category }}</p>
        </template>
      </pill>
    </div>
    <div
      v-if="isLoading"
      class="my-40 flex justify-center"
    >
      <BrightbidLoader />
    </div>
    <div v-if="subCheck && !isLoading">
      <div
        v-if="!isRemoved && !isRestored"
        key="notRemovedNotRestored"
        class="mx-10"
      >
        <div class="flex flex-row justify-between items-center mt-4 border-b pb-3">
          <h4 class="h4">{{ subCheck.title }}</h4>
        </div>
        <div class="flex flex-row justify-between mt-4 items-center">
          <div class="flex items-center">
            <Pill :color="pillColor">
              <template #text>
                <div class="flex items-center">
                  <component
                    :is="severityIcon"
                    :style="{ color: subCheck.severity === 'Warnings' ? '#FFA800' : '#F23F44' }"
                    class="mx-auto"
                  />
                  <p
                    class="h5 font-bold mx-1"
                    :class="failedSubChecksStyle"
                  >
                    {{ subCheck.issues_count }}
                  </p>
                  <p class="p2 font-medium pl-1 capitalize">{{ subCheck.severity }}</p>
                </div>
              </template>
            </Pill>
          </div>
          <p class="font-medium text-bb-text-secondary">Audited on : {{ subCheck.created_at }}</p>
        </div>
        <div class="max-h-100 overflow-y-auto mt-7">
          <div>
            <p class="p1">
              {{ subCheck.subtitle }}
            </p>
          </div>
          <div
            v-if="subCheck.recommendation"
            class="w-full bg-purple-0 rounded-2xl px-6 py-4 mt-7"
          >
            <div class="flex flex-row items-start">
              <div class="bg-white rounded-lg p-2 flex shadow-drop-12 mr-5">
                <ic-light-bulb-on-outline class="text-bb-brand-purple" />
              </div>
              <div class="flex flex-col gap-y-1">
                <p class="max-w-150">{{ subCheck.recommendation }}</p>
                <a
                  class="font-bold text-bb-brand-purple flex flex-row items-center"
                  target="_blank"
                  href=""
                  >Go to Google Ads
                  <ic-chevron
                    direction="right"
                    class="ml-1 w-4 h-4"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="mt-6">
            <SimpleTable :tableData="subCheck.result" />
          </div>
        </div>
        <div class="justify-center flex">
          <merge-button-round
            button-type="secondary"
            :disabled="false"
            class="h-9.5 w-87 my-5"
            @click="handleClose"
            >Close</merge-button-round
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pill from '@/components/input/Pill.vue'
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'
import IcClock from '@/components/icon/brightbid/IcClock.vue'
import IcAlertOutline from 'vue-material-design-icons/AlertOutline'
import IcMinusCircleOutline from 'vue-material-design-icons/MinusCircleOutline'
import IcLightBulbOnOutline from 'vue-material-design-icons/LightbulbOnOutline'
import IcChevron from '@/components/icon/ic-chevron.vue'
import IcReplay from 'vue-material-design-icons/Replay'
import IcAlertCircle from 'vue-material-design-icons/AlertCircleOutline'
import SimpleTable from '@/components/table/SimpleTable.vue'
import Toast from '@/components/shared/Toast.vue'
import BrightbidLoader from '../loader/BrightbidLoader.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AuditReportModal',
  components: {
    Pill,
    MergeButtonRound,
    IcClock,
    IcAlertOutline,
    IcChevron,
    IcAlertCircle,
    IcMinusCircleOutline,
    IcLightBulbOnOutline,
    IcReplay,
    SimpleTable,
    Toast,
    BrightbidLoader,
  },
  props: {
    siteId: {
      type: Number,
      required: true,
    },
    checkId: {
      type: String,
      required: true,
    },
    auditId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isRemoved: false,
      isRestored: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('auditCheck', { subCheck: 'getCheckDetails', checkError: 'getError' }),
    pillColor() {
      return this.subCheck.severity === 'Warnings' ? 'warningYellow' : 'errorRed'
    },
    failedSubChecksStyle() {
      return this.subCheck.severity === 'Warnings' ? 'text-bb-warning-200' : 'text-bb-error'
    },
    severityIcon() {
      return this.subCheck.severity === 'Warnings' ? 'IcAlertCircle' : 'IcAlertOutline'
    },
  },

  mounted() {
    this.fetchAuditCheckData()
  },
  methods: {
    ...mapActions('auditCheck', ['fetchCheckDetails']),

    handleClose() {
      this.$emit('close')
    },
    async fetchAuditCheckData() {
      this.isLoading = true
      await this.fetchCheckDetails({
        siteId: this.siteId,
        reportId: this.auditId,
        checkId: this.checkId,
      })

      this.isLoading = false
    },
  },
}
</script>

<style scoped>
.modal-container {
  z-index: 9000;
}
</style>
