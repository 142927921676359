<template>
  <div>
    <div class="overflow-x-auto border border-gray-200 rounded-xl">
      <table class="min-w-full table-fixed bg-white">
        <thead class="border-b">
          <tr>
            <th
              v-for="(header, index) in allHeaders"
              :key="index"
              class="h5 py-3 px-5 border-b border-gray-200 min-w-60 capitalize"
              :class="header === 'campaignName' ? 'font-medium' : ''"
            >
              {{ formatHeader(header) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, rowIndex) in paginatedData"
            :key="rowIndex"
            class="border-b odd:bg-white even:bg-neutral-0"
          >
            <td
              v-for="(header, colIndex) in allHeaders"
              :key="colIndex"
              class="py-5 px-5 border-b border-gray-200 truncate max-w-42"
            >
              {{ item[header] !== undefined ? item[header] : 'N/A' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <TablePagination
      class="py-3"
      :limit="rowsPerPage"
      :page="currentPage"
      :max="tableData.length"
      :disable-next="currentPage >= totalPages"
      :disable-prev="currentPage <= 1"
      @onChange="changePage"
    />
  </div>
</template>

<script>
import TablePagination from '@/components/table/TablePagination'

export default {
  name: 'DynamicTable',
  components: {
    TablePagination,
  },
  props: {
    tableData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      rowsPerPage: 6,
      currentPage: 1,
    }
  },
  computed: {
    allHeaders() {
      const headersSet = new Set()
      this.tableData.forEach(item => {
        Object.keys(item).forEach(key => headersSet.add(key))
      })
      return Array.from(headersSet)
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.rowsPerPage
      const end = this.currentPage * this.rowsPerPage
      return this.tableData.slice(start, end)
    },
    totalPages() {
      return Math.ceil(this.tableData.length / this.rowsPerPage)
    },
  },
  methods: {
    formatHeader(header) {
      // CamelCase conversion
      header = header.replace(/([A-Z])/g, ' $1')

      // Snake_case and kebab-case conversion
      header = header.replace(/-/g, ' ')
      header = header.replace(/_/g, ' ')

      return header.trim()
    },
    changePage(paginationData) {
      this.currentPage = paginationData.current_page
    },
  },
}
</script>

<style scoped></style>
