<template>
  <page class="main-container base-scrollbar">
    <page-section
      full-width
      bottom-border
    >
      <div
        class="flex flex-row justify-between"
        :class="{ 'mx-20': isExternal }"
      >
        <page-title>
          <template #icon>
            <ic-audit />
          </template>
          Audit
        </page-title>
        <div class="flex flex-row space-x-4 items-center">
          <merge-button-round
            button-type="brand-purple"
            :disabled="isLoading || hasPendingAudit || !!auditsError"
            @click="generateNewAudit"
          >
            <p>Run Audit</p>
          </merge-button-round>
        </div>
      </div>
    </page-section>

    <base-alert
      v-if="hasPendingAudit && !latestCompletedAudit && !auditsError"
      type="information"
      class="max-w-5xl mx-auto my-6"
    >
      <template #title> Audit running...</template>
      <template #content> You have an audit generating in the background, check back later to see the result!</template>
    </base-alert>

    <AuditLoader
      v-if="isLoading || (hasPendingAudit && !latestCompletedAudit && !auditsError)"
      :class="{ 'mx-28': isExternal }"
    />

    <div
      v-if="!isLoading && latestCompletedAudit && !auditsError"
      :class="{ 'mx-28': isExternal }"
    >
      <div
        class="py-4 px-8 flex flex-col md:flex-row gap-4 w-full items-center justify-between border-b border-neutral-50"
        :class="{
          'bg-neutral-0': !isExternal,
          'bg-white': isExternal,
        }"
      >
        <p class="h4">{{ auditHeader }}</p>
        <p class="p3 text-bb-neutral-gray">
          Last audit run: {{ daysSinceLastCreatedAudit }} - Compared to: {{ lastCompletedAuditDate }}
        </p>
      </div>
      <div class="px-3 bg-white">
        <AuditSummary :summary="auditSummary" />
      </div>

      <div class="sticky top-0 bg-white z-10 flex-row w-full justify-evenly">
        <div class="pt-4 mx-4 flex">
          <div
            class="w-1/2 text-center flex flex-row justify-center gap-2 pb-3"
            :class="{
              'border-b-2 border-bb-brand-purple text-bb-brand-purple': selectedView === 'issues',
              'border-b border-neutral-50 text-bb-neutral-gray hover:border-bb-brand-purple hover:text-bb-brand-purple cursor-pointer':
                selectedView !== 'issues',
            }"
            @click="selectedView = 'issues'"
          >
            <ic-alert-circle />
            <p class="h5">Issues</p>
          </div>
          <div class="px-2 py-1">
            <div class="w-px h-full bg-neutral-50"></div>
          </div>
          <div
            class="w-1/2 text-center flex flex-row justify-center gap-2 pb-3"
            :class="{
              'border-b-2 border-bb-brand-purple text-bb-brand-purple': selectedView === 'categories',
              'border-b border-neutral-50 text-bb-neutral-gray hover:border-bb-brand-purple hover:text-bb-brand-purple cursor-pointer':
                selectedView !== 'categories',
            }"
            @click="selectedView = 'categories'"
          >
            <ic-alert-circle />
            <p class="h5 font-medium">Categories</p>
          </div>
        </div>
      </div>

      <page-section full-width>
        <div v-if="selectedView === 'issues'">
          <AuditCheckList
            :checks="auditDetails.checks"
            @openModal="selectSubcheck"
          />
        </div>
        <div
          v-if="selectedView === 'categories'"
          class="h-1/2"
        >
          <UnderConstruction />
        </div>
      </page-section>
    </div>
    <bb-base-modal
      v-if="showModal === true"
      :width="modalConfig.width"
      @close="closeModal"
    >
      <template #container>
        <AuditReportModal
          :check-id="selectedSubcheck"
          :audit-id="latestAudit.id"
          :site-id="selectedSite.value"
          @close="closeModal"
        ></AuditReportModal>
      </template>
    </bb-base-modal>

    <div v-if="auditsError && !isLoading">
      <div class="flex flex-col items-center text-center mt-20">
        <img
          src="/content/images/brightbid/bidbot-broken.svg"
          class="mb-6"
        />
        <p class="h3">Audit failed...</p>
        <p>Please try to refresh the page and start a new Audit.</p>
      </div>
    </div>

    <div
      v-if="!isLoading && !audits && !auditsError"
      class="flex flex-col justify-center mt-32"
    >
      <div class="flex flex-row w-full justify-between items-center max-w-215 mx-auto">
        <div class="w-full">
          <img src="/content/images/brightbid/audit-artwork.svg" />
        </div>
        <div class="flex flex-col my-16">
          <p class="h3 mb-4">Welcome to your Audits</p>
          <p>
            Start your first audit to receive an account score, detailed insights and actionable recommendations to
            enhance your performance!
          </p>
          <merge-button-round
            class="mr-auto mt-6"
            button-type="primary"
            @click="generateNewAudit"
          >
            <p>Run Audit</p>
          </merge-button-round>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection'
import IcAudit from '@/components/icon/brightbid/ic-audit.vue'
import IcAlertCircle from 'vue-material-design-icons/AlertCircleOutline'
import IcLoading from 'vue-material-design-icons/Loading.vue'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal.vue'
import AuditReportModal from '@/components/modals/AuditReportModal.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import AuditLoader from '@/components/audit-components/AuditLoader'
import AuditSummary from '@/components/audit-components/AuditSummary'
import AuditCheckList from '@/components/audit-components/AuditCheckList.vue'
import UnderConstruction from '@/views/UnderConstruction.vue'
import BaseAlert from '@/components/alert/BaseAlert'
import BrightBidLoader from '@/components/loader/BrightbidLoader.vue'
import dayjs from 'dayjs'

export default {
  name: 'ReportsIndex',
  components: {
    Page,
    PageSection,
    IcAlertCircle,
    IcLoading,
    BbBaseModal,
    AuditReportModal,
    IcAudit,
    AuditLoader,
    AuditSummary,
    AuditCheckList,
    UnderConstruction,
    BaseAlert,
    BrightBidLoader,
  },
  props: {
    isExternal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      selectedView: 'issues',
      audit: null,
      showModal: false,
      selectedSubcheck: null,
      modalConfig: {
        width: '800px',
      },
    }
  },

  computed: {
    ...mapState('site', ['selectedSite', 'siteDetails']),
    ...mapState('audit', ['audits', 'auditDetails', 'auditSummary', 'auditsError']),
    ...mapGetters('audit', ['latestCompletedAudit', 'latestCreatedAudit', 'hasPendingAudit']),
    ...mapGetters('auth', ['userAuditGoogleCredentials']),
    auditHeader() {
      if (this.isExternal && this.userAuditGoogleCredentials) {
        return this.userAuditGoogleCredentials.googleCustomerName + ' Audit'
      } else if (this.selectedSite) {
        return this.selectedSite.label + ' - #' + this.ammountOfCompletedAudits
      }
      return 'No audits available'
    },

    daysSinceLastCreatedAudit() {
      if (!this.latestCreatedAudit) {
        return null
      }
      return dayjs(this.latestCompletedAudit.created_at).format('MM/DD/YYYY')
    },

    lastCompletedAuditDate() {
      if (!this.latestCompletedAudit) {
        return null
      }
      return dayjs(this.latestCompletedAudit.created_at).format('MM/DD/YYYY')
    },
    ammountOfCompletedAudits() {
      if (!this.audits) {
        return 0
      }

      return this.audits.filter(audit => audit.status === 'completed').length
    },
    identification() {
      return this.isExternal ? this.userAuditGoogleCredentials.googleCustomerId : this.selectedSite?.value
    },
  },

  watch: {
    async selectedSite(newVal, oldVal) {
      if (newVal && newVal.value !== oldVal?.value) {
        this.isLoading = true
        await this.fetchSiteDetails(newVal.value)
        this.isLoading = false
      }
    },
  },
  async mounted() {
    this.isLoading = true

    const startTime = Date.now()

    await this.fetchAuditData()

    const fetchTime = Date.now() - startTime

    const remainingTime = Math.max(1000 - fetchTime, 0)

    setTimeout(() => {
      this.isLoading = false
    }, remainingTime)
  },

  methods: {
    ...mapActions('audit', [
      'fetchAudits',
      'fetchAuditDetails',
      'fetchAuditSummary',
      'fetchExternalAudits',
      'fetchExternalAuditDetails',
      'fetchExternalAuditSummary',
    ]),
    closeModal() {
      this.selectedSubcheck = null
      this.showModal = false
    },
    selectSubcheck(item) {
      this.selectedSubcheck = item
      this.showModal = true
    },
    ...mapActions('toast', ['loadToast']),

    async fetchAuditData() {
      // Choose the appropriate action based on `isExternal`
      if (this.isExternal) {
        await this.fetchExternalAudits({ customerId: this.identification })

        if (this.latestCompletedAudit) {
          await this.fetchExternalAuditDetails({
            customerId: this.identification,
            auditReportId: this.latestCompletedAudit.id,
          })
          await this.fetchExternalAuditSummary({
            customerId: this.userAuditGoogleCredentials.googleCustomerId,
            auditReportId: this.latestCompletedAudit.id,
          })
        }
      } else {
        await this.fetchAudits({ siteId: this.identification })
        if (this.latestCompletedAudit) {
          await this.fetchAuditDetails({
            siteId: this.identification,
            auditReportId: this.latestCompletedAudit.id,
          })
          await this.fetchAuditSummary({
            siteId: this.identification,
            auditReportId: this.latestCompletedAudit.id,
          })
        }
      }
    },
    async generateNewAudit() {
      if (this.isExternal) {
        try {
          await this.$http.post(`/audit/customer/${this.identification}/report`)
          await this.loadToast({
            title: 'Success!',
            message: 'A new audit is being generated.',
            type: 'success',
          })
        } catch (error) {
          await this.loadToast({
            title: 'Error',
            message: 'Audit failed to generate. Please try again.',
            type: 'error',
          })
        }
      } else {
        try {
          await this.$http.post(`/audit/site/${this.identification}/report`)
          await this.loadToast({
            title: 'Success!',
            message: 'A new audit is being generated.',
            type: 'success',
          })
        } catch (error) {
          await this.loadToast({
            title: 'Error',
            message: 'Audit failed to generate. Please try again.',
            type: 'error',
          })
        }
      }
      this.fetchAuditData()
    },
  },
}
</script>

<style lang="scss">
.main-container {
  overflow-y: auto;
  height: calc(100vh - 103px);
}
</style>
